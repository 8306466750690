import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { MainContextProvider } from "./context/main-context";
import "./scss/base.scss";

const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(
    <React.StrictMode>
      <BrowserRouter>
        <MainContextProvider>
          <App />
        </MainContextProvider>
      </BrowserRouter>
    </React.StrictMode>,
    rootElement
  );
} else {
  ReactDOM.render(
    <React.StrictMode>
      <BrowserRouter>
        <MainContextProvider>
          <App />
        </MainContextProvider>
      </BrowserRouter>
    </React.StrictMode>,
    rootElement
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);

// commenting service worker - will use this in future
// navigator.serviceWorker.register("service-worker.js", {
//   scope: "/app/",
// });
