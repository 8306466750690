import axios from "axios";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import apiCall from "../api/axios";
import apiConstants from "../constants/apiConstants";
import { SUCCESS_STATUS_CODE } from "../constants/constant";
const MainContext = React.createContext({
  isLoggedIn: false, // user logged in flag
  isMobile: window.innerWidth < 768,
  isTablet: window.innerWidth < 960,
  isModal: false, // show and hide modal
  isAction: false, // any action in content card like bookmark and like
  modalType: "",
  loader: 0,
  userData: null,
  topicList: [],
  expertiseList: [],
  sortingList: [],
  // no need to define this function,
  // just for getting suggestions from IDE while accessing context
  onLogout: () => { },
  onLogin: () => { },
  setModal: () => { },
  setAction: () => { },
  setModalType: () => { },
  setLoader: () => { },
  setUser: () => { },
});

export const MainContextProvider = (props) => {
  const [isLoggedIn, setUserLoggedIn] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isTablet, setIsTablet] = useState(window.innerWidth < 960);
  const [isModal, setIsModal] = useState(false);
  const [isAction, setIsAction] = useState(false);
  const [modalType, setModalTypeHandler] = useState("");
  const [loader, setLoaderValue] = useState(0);
  const [userData, setUserData] = useState(null);
  const [topicList, setTopicList] = useState([]);
  const [expertiseList, setExpertiseList] = useState([]);
  const [sortingList, setSortingList] = useState([]);
  const history = useHistory();

  useEffect(() => {
    const accessToken = Cookies.get("access_token");
    const refreshToken = Cookies.get("refresh_token");
    if (refreshToken) {
      axios
        .post(apiConstants.auth.refreshToken + "?refreshToken=" + refreshToken)
        .then((response) => {
          if (response.data.apiResponseStatus.code === SUCCESS_STATUS_CODE) {
            document.cookie =
              "access_token=" +
              response.data.responseObject.oauth2AccessToken.access_token +
              "; path=/; max-age=3600";
            document.cookie =
              "refresh_token=" +
              response.data.responseObject.oauth2AccessToken.refresh_token +
              "; path=/; max-age=2629800";
            window.localStorage.setItem(
              "access_token",
              response.data.responseObject.oauth2AccessToken.access_token
            );
            /* window.localStorage.setItem(
            "refresh_token",
            response.data.responseObject.oauth2AccessToken.refresh_token
          );*/
          }
        })
        .then(() => {
          getSession();
        });
    }
    apiCall.getRequest(apiConstants.consumer.category, (response) => {
      setTopicList(response.data.responseObject);
    });
    apiCall.getRequest(apiConstants.list.topics, (response) => {
      setExpertiseList(response.data.responseObject);
    });
    setSortingList([
      { name: "Most Views", id: "VIEWS" },
      { name: "Most Likes", id: "LIKES" },
      { name: "Trending", id: "TRENDING" },
      { name: "Latest", id: "PUBLISHED_DATE" },
    ]);
  }, [isLoggedIn]);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setIsMobile(window.innerWidth < 768);
      setIsTablet(window.innerWidth < 960);
    });
  }, [isMobile, isTablet]);

  const getSession = () => {
    const portalHeaders = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Cookies.get("access_token"),
    };
    axios
      .get(apiConstants.auth.getSession, {
        headers: portalHeaders,
      })
      .then((sessionData) => {
        setUserData(sessionData.data.responseObject);
        setUserLoggedIn(true);
        setIsModal(false);
        setIsAction(false);
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          getSession();
        }
      });
  };

  const loginHandler = () => {
    getSession();
    history.go(0);
  };

  const userHandler = (user) => {
    setUserData(user);
  };

  const logoutHandler = () => {
    document.cookie = "access_token=; path=/; max-age=60";
    document.cookie = "refresh_token= ; path=/; max-age=60";
    localStorage.clear();
    setUserLoggedIn(false);
    history.go(0);
  };

  const modalHandler = () => {
    setIsModal((prev) => {
      return !prev;
    });
    setModalTypeHandler("");
  };

  const actionHandler = () => {
    setIsAction((prev) => {
      return !prev;
    });
  };

  const modalTypeHandler = (type) => {
    setModalTypeHandler(type);
  };

  const loaderHandler = (val) => {
    setLoaderValue((loaderPrev) => {
      if (val) {
        return ++loaderPrev;
      } else {
        return --loaderPrev;
      }
    });
  };

  return (
    <MainContext.Provider
      value={{
        isMobile: isMobile,
        isTablet: isTablet,
        isLoggedIn: isLoggedIn,
        isModal: isModal,
        isAction: isAction,
        modalType: modalType,
        loader: loader,
        userData: userData,
        topicList: topicList,
        expertiseList: expertiseList,
        sortingList: sortingList,
        onLogout: logoutHandler,
        onLogin: loginHandler,
        setModal: modalHandler,
        setAction: actionHandler,
        setModalType: modalTypeHandler,
        setLoader: loaderHandler,
        setUser: userHandler,
      }}>
      {props.children}
    </MainContext.Provider>
  );
};

export default MainContext;
