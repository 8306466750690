import { BACKEND_BASE_PATH } from "./constant";

const apiConstants = {
  leaders: {
    leaderslist: `${BACKEND_BASE_PATH}/analytics/api/v1/leaders`,
    powerlist: `${BACKEND_BASE_PATH}/analytics/api/v1/leaders/powerlist`,
    leadersProfile: `${BACKEND_BASE_PATH}/account/api/v1/userDetail/`,
    topContent: `${BACKEND_BASE_PATH}/content/api/v1/top_content/Influencer`,
  },
  list: {
    topics: `${BACKEND_BASE_PATH}/account/api/v1/expertise`,
    leaders: `${BACKEND_BASE_PATH}/account/api/v1/influencer`,
    contentDetails: `${BACKEND_BASE_PATH}/content/api/v1/particularContent/61052110a77ed569a51bb7cd`,
    contentList: `${BACKEND_BASE_PATH}/content/api/v1/consumer-portal/list`,
    usersBookmarkedContent: `${BACKEND_BASE_PATH}/content/api/v1/bookmark-like/bookmark-content/all`,
  },
  content: {
    topContent: `${BACKEND_BASE_PATH}/content/api/v1/top_content/all`,
    leaderContent: `${BACKEND_BASE_PATH}/content/api/v1/influencer/list-for-consumer`,
    likeContent: `${BACKEND_BASE_PATH}/content/api/v1/bookmark-like/like`,
    bookmarkContent: `${BACKEND_BASE_PATH}/content/api/v1/bookmark-like/bookmark`,
    singleTopic: `${BACKEND_BASE_PATH}/content/api/v1/by/category/all-content`,
  },
  dashboard: {
    pageData: `${BACKEND_BASE_PATH}/analytics/api/v1/dashboard/consumer`,
    latestContent: `${BACKEND_BASE_PATH}/content/api/v1/consumer-portal/latest-content`,
  },
  media: {
    blog: `${BACKEND_BASE_PATH}/content/api/v1/particular-content`,
    comment: `${BACKEND_BASE_PATH}/content/api/v1/comment`,
    like: `${BACKEND_BASE_PATH}/content/api/v1/bookmark-like/like`,
    bookmark: `${BACKEND_BASE_PATH}/content/api/v1/bookmark-like/bookmark`,
    ebook: `${BACKEND_BASE_PATH}/content/api/v1/url`,
  },
  auth: {
    loginRegister: `${BACKEND_BASE_PATH}/account/api/v1/register-and-login`,
    loginConsumer: `${BACKEND_BASE_PATH}/account/api/v1/consumer-login`,
    login: `${BACKEND_BASE_PATH}/account/api/v1/login`,
    signup: `${BACKEND_BASE_PATH}/account/api/v1/register`,
    refreshToken: `${BACKEND_BASE_PATH}/account/api/v1/refresh`,
    resetPassword: `${BACKEND_BASE_PATH}/account/api/v1/set-password`,
    forgotPassword: `${BACKEND_BASE_PATH}/account/api/v1/forgot`,
    resendVerificationMail: `${BACKEND_BASE_PATH}/account/api/v1/resend-verification`,
    verifyEmail: `${BACKEND_BASE_PATH}/account/api/v1/verify-email`,
    authenticate: `${BACKEND_BASE_PATH}/account/api/v1/login`,
    getSession: `${BACKEND_BASE_PATH}/account/api/v1/session`,
  },
  consumer: {
    consumerProfile: `${BACKEND_BASE_PATH}/account/api/v1/consumer/profile/update`,
    presigned: `${BACKEND_BASE_PATH}/content/api/v1/influencer/presignedurl`,
    getConsumerData: `${BACKEND_BASE_PATH}/account/api/v1/consumer/profile/details`,
    searchFunction: `${BACKEND_BASE_PATH}/account/api/v1/consumer/function`,
    searchIndustry: `${BACKEND_BASE_PATH}/account/api/v1/consumer/industry`,
    topLeadersFollow: `${BACKEND_BASE_PATH}/content/api/v1/top_content/topLeader`,
    interestedTopics: `${BACKEND_BASE_PATH}/account/api/v1/interested-topics`,
    category: `${BACKEND_BASE_PATH}/content/api/v1/category`,
  },
  topics: {
    allTopics: `${BACKEND_BASE_PATH}/content/api/v1/by/categories/content`,
    topicContentList: `${BACKEND_BASE_PATH}/content/api/v1/consumer-portal/list`,
  },
  registerAsLeader: {
    country: `${BACKEND_BASE_PATH}/account/api/v1/leader-registration/get-country`,
    submitRegister: `${BACKEND_BASE_PATH}/account/api/v1/leader-registration/register`,
  },
  follow: {
    addFollower: `${BACKEND_BASE_PATH}/content/api/v1/follow/`,
    getLeadersFollowing: `${BACKEND_BASE_PATH}/content/api/v1/follow/following-leaders`,
    isFollower: `${BACKEND_BASE_PATH}/content/api/v1/follow/`,
    leaderFollower: `${BACKEND_BASE_PATH}/content/api/v1/follow/following-leaders`,
  },
  search: {
    searchData: `${BACKEND_BASE_PATH}/search/api/v1`,
    searchInfluencer: `${BACKEND_BASE_PATH}/search/api/v1/influencer/details`,
    searchCategory: `${BACKEND_BASE_PATH}/search/api/v1/category/details`,
    searchContent: `${BACKEND_BASE_PATH}/search/api/v1/content/details`,
    searchExpertise: `${BACKEND_BASE_PATH}/search/api/v1/expertise/details`,
    expertiseLeaders: `${BACKEND_BASE_PATH}/account/api/v1/influencer/by/expertise`,
  },
};

export default apiConstants;
