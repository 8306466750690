export const BACKEND_BASE_PATH = process.env.REACT_APP_API_URL;

export const SUCCESS_STATUS_CODE = 1000;

// REGEX
export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const NAME_REGEX = /^[A-Z.' ]*$/i;
export const PHONE_NUMBER_REGEX = /^\d{8,14}$/;
export const PASSWORD_REGEX =
  /^(?=.*[A-Z])[A-Za-z\d.@$!%*#\"\'\“\‘\’\”,^:;<>?/?+\-\\|=_&{}()\[\]~`]{6,24}$/;
export const URL_REGX =
  /(?:https?:)?\/?\/?(?:www\.)?[a-zA-Z0-9@:%._\\+~#?&/=]{2,256}.[a-z]{2,6}([a-zA-Z0-9@:%._\\+~#?&/=]*)/;

//Terms and conditions
export const TERMS_AND_CONDITIONS =
  "https://leadershum.s3.amazonaws.com/PROD/Influencer+agreement/leadersHum+Terms+%26+Condition.pdf";
