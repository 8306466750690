import axios from "axios";
import Cookies from "js-cookie";
import apiConstants from "../constants/apiConstants";
import { SUCCESS_STATUS_CODE } from "../constants/constant";

const headers = {};

function handleResponse(response, callback) {
  if (
    response &&
    response.data.apiResponseStatus &&
    response.data.apiResponseStatus.code === SUCCESS_STATUS_CODE
  ) {
    callback(response, "success");
  } else {
    callback(response, "error");
  }
}

function handleError(error, callback) {
  console.log(error);
  const originalRequest = error.config;
  // token expired
  if (error.response.status === 401) {
    const refreshToken = Cookies.get("refresh_token");
    // refresh token
    axios
      .post(apiConstants.auth.refreshToken + "?refreshToken=" + refreshToken)
      .then((response) => {
        if (response.data.apiResponseStatus.code === SUCCESS_STATUS_CODE) {
          document.cookie =
            "access_token=" +
            response.data.responseObject.oauth2AccessToken.access_token +
            "; path=/; max-age=3600";
          document.cookie =
            "refresh_token=" +
            response.data.responseObject.oauth2AccessToken.refresh_token +
            "; path=/; max-age=2629800";
          window.localStorage.setItem(
            "access_token",
            response.data.responseObject.oauth2AccessToken.access_token
          );
          window.localStorage.setItem(
            "refresh_token",
            // JSON.stringify(
            response.data.responseObject.oauth2AccessToken.refresh_token
            // )
          );
          originalRequest.headers.Authorization =
            "bearer " + Cookies.get("access_token");
          return axios.request(error.config).then((response) => {
            callback(response, "success");
          });
        } else {
          // logout if refresh token is expired
          document.cookie = "refresh_token= ; path=/; max-age=60";

          window.location.href = "/external/login";
        }
      });
  } else {
    // redirect to error page
    window.location.href = "/error";
  }
}

const apiCall = {
  getRequest(path, callback, params) {
    if (Cookies.get("access_token")) {
      headers.Authorization = "bearer " + Cookies.get("access_token");
    }
    if (params) {
      path =
        path +
        "?" +
        Object.entries(params)
          .map((value) => {
            return value.join("=");
          })
          .join("&");
    }
    axios
      .get(path, { headers })
      .then((response) => {
        handleResponse(response, callback);
      })
      .catch((error) => {
        handleError(error, callback);
      });
  },
  postRequest(path, callback, payload, params) {
    if (Cookies.get("access_token")) {
      headers.Authorization = "bearer " + Cookies.get("access_token");
    }
    if (params) {
      path =
        path +
        "?" +
        Object.entries(params)
          .map((value) => {
            return value.join("=");
          })
          .join("&");
    }
    axios
      .post(path, payload, { headers })
      .then((response) => {
        handleResponse(response, callback);
      })
      .catch((error) => {
        handleError(error, callback);
      });
  },
  putRequest(path, callback, payload, params) {
    if (Cookies.get("access_token")) {
      headers.Authorization = "bearer " + Cookies.get("access_token");
    }
    if (params) {
      path =
        path +
        "?" +
        Object.entries(params)
          .map((value) => {
            return value.join("=");
          })
          .join("&");
    }
    axios
      .put(path, payload, { headers })
      .then((response) => {
        handleResponse(response, callback);
      })
      .catch((error) => {
        handleError(error, callback);
      });
  },
  deleteRequest(path, callback, params) {
    if (Cookies.get("access_token")) {
      headers.Authorization = "bearer " + Cookies.get("access_token");
    }
    if (params) {
      path =
        path +
        "?" +
        Object.entries(params)
          .map((value) => {
            return value.join("=");
          })
          .join("&");
    }
    axios
      .delete(path, { headers })
      .then((response) => {
        handleResponse(response, callback);
      })
      .catch((error) => {
        handleError(error, callback);
      });
  },
};

export default apiCall;
